import store from '@/store'

const adminguard = (to, from, next) => {
    if(localStorage.getItem('educanToken' + store.getters.authuser.id) != null && store.getters.authuser != null && store.getters.authuser.id ){
      if (store.getters.authuser.role == 'admin') {
        return next()
      } else {
        return next({ name: 'login' })
      }
    }else{
        return next({ name: 'login' })
    }
};


let routes = [{
  path: "/",
  props: true,
  beforeEnter: adminguard,
  component: () => import("@/views/app/main.vue"),
  children: [
    // ============================================== Dashboard ============================================
    {
      path: "/",
      name: 'dashboard',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/dashboard/main.vue"),
      meta: {
        title: 'Dashboard',
      },
    },

    {
      path: "/entrants",
      name: 'entrants_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/entrants/main.vue"),
      children: [
        {
          path: "",
          name: 'entrants',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/entrants/entrants.vue"),
          meta: {
            title: 'Data Entrants',
          },
        },
      ]
    },


    {
      path: "/moderators",
      name: 'moderators_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/moderators/main.vue"),
      children: [
        {
          path: "",
          name: 'moderators',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/moderators/moderators.vue"),
          meta: {
            title: 'Data Moderators',
          },
        },
      ]
    },

    {
      path: "/students",
      name: 'student_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/students/main.vue"),
      children: [
        {
          path: "",
          name: 'students',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/students/students.vue"),
          meta: {
            title: 'Students',
          },
        },
      ]
    },

    {
      path: "/teachers",
      name: 'teacher_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/teachers/main.vue"),
      children: [
        {
          path: "",
          name: 'teachers',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/teachers/teachers.vue"),
          meta: {
            title: 'Teachers',
          },
        },

        {
          path: "requests",
          name: 'teacher_requests',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/teachers/requests.vue"),
          meta: {
            title: 'Teacher Requests',
          },
        },

        {
          path: ":id",
          name: 'teacher_details',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/teachers/details.vue"),
          meta: {
            title: 'Teacher Details',
          },
        },
      ]
    },


    {
      path: "/help_centers",
      name: 'help_center_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/help_center/main.vue"),
      children: [
        {
          path: "",
          name: 'help_center',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/help_center/center.vue"),
          meta: {
            title: 'Help Center Personnel',
          },
        },
      ]
    },


    {
      path: "/shop",
      name: 'shop_pages',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/shop/main.vue"),
      children: [
        {
          path: "",
          name: 'e_commerce',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/shop/shop.vue"),
          meta: {
            title: 'Educan Sales Personnel',
          },
        },
      ]
    },

    {
      path: "/users",
      name: 'users',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/users/users.vue"),
      meta: {
        title: 'Users',
      },
    },

    {
      path: "/account",
      name: 'account',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/account/main.vue"),
      children: [
        {
          path: "profile",
          name: 'profile',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/account/profile.vue"),
          meta: {
            title: 'Profile',
          },
        },
      ]
    },


    {
      path: "/notifications",
      name: '',
      props: true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/notifications/main.vue"),
      children: [
        {
          path: "",
          name: 'notifications',
          props: true,
          beforeEnter: adminguard,
          component: () => import("@/views/app/notifications/notifications.vue"),
          meta: {
            title: 'notifications',
          },
        },
      ]
    },

  ]
}]
export default routes;