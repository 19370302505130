import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import users from './modules/users'
import statistics from './modules/statistics'
import shop from './modules/shop'
import adverts from './modules/adverts'
import teachers from './modules/teachers'
import notifications from './modules/notifications'
import global from './modules/global';

export default createStore({
  modules: {
    auth, 
    utils,
    users,
    statistics,
    shop,
    adverts,
    teachers,
    notifications,
    global,
  },
    
  plugins: [createPersistedState(
    {
      key : 'educan.com',
      paths: ['auth','utils'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
