export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        students :'', 
        student: '', 
        teachers :'', 
        teacher: '', 
        moderators :'', 
        moderator: '', 
        entrants :'', 
        entrant: '', 
        e_commerces :'', 
        e_commerce: '', 
        help_centers :'', 
        help_center :'',
        counts :'',
    },
    
    getters : {
        students: (state )=> state.students,
        student: (state )=> state.student,
        teachers: (state )=> state.teachers,
        teacher: (state) => state.teacher,
        moderators: (state )=> state.moderators,
        moderator: (state) => state.moderator,
        entrants: (state )=> state.entrants,
        entrant: (state) => state.entrant,
        e_commerces: (state )=> state.e_commerces,
        e_commerce: (state) => state.e_commerce,
        help_centers: (state )=> state.help_centers,
        help_center: (state )=> state.help_center,
        counts: (state )=> state.counts,
    },


    mutations : { 
        setstudents :(state,students ) => (state.students = students), 
        setstudent: (state, student) => (state.student = student), 
        setteachers :(state,teachers ) => (state.teachers = teachers), 
        setteacher: (state, teacher) => (state.teacher = teacher), 
        setmoderators :(state,moderators ) => (state.moderators = moderators), 
        setmoderator: (state, moderator) => (state.moderator = moderator), 
        setentrants :(state,entrants ) => (state.entrants = entrants), 
        setentrant: (state, entrant) => (state.entrant = entrant), 
        sete_commerces :(state,e_commerces ) => (state.e_commerces = e_commerces), 
        sete_commerce: (state, e_commerce) => (state.e_commerce = e_commerce), 
        sethelp_centers :(state,help_centers ) => (state.help_centers = help_centers), 
        sethelp_center :(state,help_center ) => (state.help_center = help_center), 
        setcounts :(state,counts ) => (state.counts = counts), 
    },

    actions: {
        
        async get_counts( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_counts')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/counts", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcounts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_students( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_students')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/students", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setstudents', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_teachers( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teachers')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/teachers", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_moderators( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_moderators')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/moderators", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setmoderators', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_entrants( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_entrants')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/entrants", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setentrants', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_e_commerces( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_e_commerces')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/ecommerces", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sete_commerces', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_help_centers( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_help_centers')
            return new Promise((resolve, reject) => {
            axios.post("/admin/statistics/help", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('sethelp_centers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}
