export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        teacher_requests :'',
        teacher_request :'', 
    },
    
    getters : {
        teacher_requests: (state )=> state.teacher_requests,
        teacher_request: (state )=> state.teacher_request,
    },


    mutations : { 
        setteacher_requests :(state,teacher_requests ) => (state.teacher_requests = teacher_requests), 
        setteacher_request :(state,teacher_request ) => (state.teacher_request = teacher_request), 
    },

    actions : {
        async get_teacher_requests( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teacher_requests')
            return new Promise((resolve, reject) => {
            axios.post("/admin/teacher_requests?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteacher_requests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_teacher_request( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teacher_request' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/teacher_requests/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteacher_request', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async change_teacher_request_approval_status({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'change_teacher_request_approval_status' + id)
            return new Promise((resolve, reject) => {
                axios.post(`/admin/teacher_requests/approve/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setteacher_requests', response.data.res)
                        if (response.data.res.data[0].approval_status == 1) {
                            toast.success("teacher_request Approved Successfully")
                        } else {
                            toast.success("teacher_request Disapproved Successfully")
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async delete_teacher_request( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_teacher_request' + id)
            return new Promise((resolve, reject) => {
            axios.post("/admin/teacher_requests/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteacher_requests', response.data.res)
                toast.success("teacher_request Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}