export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        grades : '',
        subjects:'',
    },
    
    getters : {
        grades: (state )=> state.grades,
        subjects: (state )=> state.subjects,
    },


    mutations : {
        setgrades :(state,grades ) => (state.grades = grades), 
        setsubjects :(state,subjects ) => (state.subjects = subjects),
    },

    actions : {

        async get_grades( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grades')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/grades?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setgrades', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_subjects( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subjects')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/subjects?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },   
}